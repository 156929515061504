<template>
    <UiCard class="flex flex-col text-left h-full overflow-auto">
        <UiCardHeader>
            Sélectionner les participants
        </UiCardHeader>
        <div class="px-4 overflow-auto grow">
            <UiHorizontalTabs
                class="flex flex-col"
                :tabs="[
                    { label: 'Parents', slot: 'parents' },
                    { label: 'Personnel', slot: 'staff' }
                ]"
            >
                <template #staff>
                    <div class="relative h-full overflow-auto grow">
                        <ul class="relative z-0 divide-y divide-gray-200">
                            <li
                                v-for="staffUser in staff.filter(s => !!s.user)"
                                :key="staffUser.uuid"
                            >
                                <div class="relative flex items-center px-6 py-5">
                                    <div class="shrink-0 hidden mr-3 xl:block">
                                        <UiAvatar
                                            class="w-10 h-10"
                                            :path="staffUser.user.avatar_path"
                                        />
                                    </div>
                                    <div class="flex-1 min-w-0">
                                        <p class="text-sm font-medium text-gray-900">
                                            {{ staffUser.user.full_name }} <span v-if="staffUser.function">({{ staffUser.function }})</span>
                                        </p>
                                        <p class="inline-flex items-center text-sm text-gray-500 truncate">
                                            {{ staffUser.email }}
                                        </p>
                                    </div>
                                    <button
                                        v-if="selectedUserUuids.includes(staffUser.user.uuid)"
                                        class="btn btn-red btn-icon"
                                        type="button"
                                        @click="removeFromSelectedUsers(staffUser.user)"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="currentColor"
                                            class="w-5 h-5"
                                        >
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                d="M22 10.5h-6m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z"
                                            />
                                        </svg>
                                    </button>
                                    <button
                                        v-else
                                        class="btn btn-green btn-icon"
                                        type="button"
                                        @click="addUser(staffUser.user)"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="currentColor"
                                            class="w-5 h-5"
                                        >
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z"
                                            />
                                        </svg>
                                    </button>
                                </div>
                            </li>
                        </ul>
                    </div>
                </template>
                <template #parents>
                    <div class="relative h-full overflow-auto grow">
                        <div class="p-1">
                            <UiInput
                                v-model="search"
                                type="search"
                                name="search"
                                placeholder="Chercher un parent..."
                            />
                        </div>
                        <UiLoader v-if="filteredParents.length === 0 && isLoading" />
                        <ul class="relative z-0 divide-y divide-gray-200">
                            <li
                                v-for="parentUser in filteredParents"
                                :key="parentUser.uuid"
                            >
                                <div class="relative flex items-center px-6 py-5">
                                    <div class="flex-shrink-0 hidden mr-3 xl:block">
                                        <UiAvatar
                                            class="w-10 h-10"
                                            :path="parentUser.user.avatar_path"
                                        />
                                    </div>
                                    <div class="flex-1 min-w-0">
                                        <p class="text-sm font-medium text-gray-900">
                                            <span>{{ parentUser.user.full_name }}</span>
                                            <span class="ml-2 text-xs text-gray-500">{{ parentUser.email }}</span>
                                        </p>
                                        <div class="text-sm text-gray-500">
                                            {{ parentUser.babies[0].parent_type }} de
                                            {{ parentUser.babies.map(b => b.name).join(', ') }}
                                        </div>
                                    </div>
                                    <button
                                        v-if="selectedUserUuids.includes(parentUser.user.uuid)"
                                        class="btn btn-red btn-icon"
                                        type="button"
                                        @click="removeFromSelectedUsers(parentUser.user)"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="currentColor"
                                            class="w-5 h-5"
                                        >
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                d="M22 10.5h-6m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z"
                                            />
                                        </svg>
                                    </button>
                                    <button
                                        v-else
                                        class="btn btn-green btn-icon"
                                        type="button"
                                        @click="addUser(parentUser.user)"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="currentColor"
                                            class="w-5 h-5"
                                        >
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z"
                                            />
                                        </svg>
                                    </button>
                                </div>
                            </li>
                        </ul>
                    </div>
                </template>
            </UiHorizontalTabs>
        </div>
        <UiCardBody
            v-if="selectedUsers.length"
            class="border-t"
        >
            <div>
                <label
                    for="participants"
                    class="mb-1 text-left label"
                >
                    Participants à ajouter
                </label>
                <div class="flex flex-wrap -mx-1">
                    <div
                        v-for="selectedUser in selectedUsers"
                        :key="selectedUser.uuid"
                        class="flex items-center py-1 pl-1 pr-3 m-1 space-x-2 rounded-full bg-slate-200"
                    >
                        <button
                            class="flex items-center justify-center w-5 h-5 text-gray-400 rounded-full hover:text-red-400"
                            type="button"
                            @click="removeFromSelectedUsers(selectedUser)"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                class="w-4 h-4"
                            >
                                <path
                                    d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"
                                />
                            </svg>
                        </button>
                        <span class="text-sm text-gray-800">{{ selectedUser.full_name }}</span>
                    </div>
                </div>
            </div>
        </UiCardBody>
        <UiCardFooter class="text-right">
            <button
                class="btn btn-primary"
                :class="{ loading: isLoading }"
                :disabled="isLoading"
                @click="submit"
            >
                Soumettre
            </button>
        </UiCardFooter>
    </UiCard>
</template>

<script>
import { defineComponent } from 'vue'
import { loadingMixin, searchMixin } from 'abcreche-ui'
import parentOptions from '~/mixins/parents/parentOptions'
import StaffRepository from '~/repositories/Creche/StaffRepository.js'
import ChatRepository from '~/repositories/Shared/ChatRepository.js'

export default defineComponent({
    name: 'ChatThreadAddParticipant',
    mixins: [loadingMixin, searchMixin, parentOptions],

    props: {
        thread: {
            type: Object,
            required: true
        }
    },
    emits: ['updated'],

    data: () => ({
        search: '',
        staff: [],
        selectedUsers: []
    }),

    computed: {
        selectedUserUuids () {
            return this.selectedUsers.map(u => u.uuid)
        },
        filteredParents () {
            return this.parents.filter(p => !!p.user)
                .filter((parent) => {
                    return this.searchHasResult([
                        parent.first_name,
                        parent.last_name,
                        ...parent.babies.map(b => b.name)
                    ], this.search)
                })
        }
    },
    created () {
        this.fetchStaff()
    },

    methods: {
        fetchStaff () {
            StaffRepository.index()
                .then((response) => {
                    this.staff = response.data
                })
                .catch(error => this.pageError(error))
        },

        addUser (user) {
            this.selectedUsers.push(user)
        },

        removeFromSelectedUsers (user) {
            this.selectedUsers = this.selectedUsers.filter(u => u.uuid !== user.uuid)
        },

        submit () {
            this.load()
            ChatRepository.addParticipants(this.thread.id, {
                user_uuids: this.selectedUserUuids
            })
                .then(() => {
                    this.done()
                    this.$emitter.emit('chat:fetch-threads', this.fetchThreads)
                    this.$emit('updated')
                })
        }
    }

})
</script>
